export const newsData = [
    {
        image: require("../../assets/images/blog1.jpg"), 
        tag: "Technology", 
        date: "25 Feb, 2020", 
        title: "The Most Popular New top Business Apps", 
        content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum."
    },
    {
        image: require("../../assets/images/blog2.jpg"), 
        tag: "Agency", 
        date: "27 Feb, 2020", 
        title: "The Best Marketing top Management Tools", 
        content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum."
    },
    {
        image: require("../../assets/images/blog3.jpg"), 
        tag: "IT", 
        date: "28 Feb, 2020", 
        title: "3 WooCommerce Plugins to Boost Sales", 
        content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum."  
    },
    {
        image: require("../../assets/images/blog4.jpg"), 
        tag: "IT", 
        date: "28 Feb, 2020", 
        title: "How To Setup Redux In React Next Application", 
        content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum."
    }
]